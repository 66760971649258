// React.
import React, { useEffect, Fragment, useState } from 'react';

// Translations.
import { useTranslation } from 'react-i18next';

// Material UI.
import { useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Badge from '@material-ui/core/Badge';
import StorefrontIcon from '@material-ui/icons/Storefront';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Button from '@material-ui/core/Button';
import WebIcon from '@material-ui/icons/Web';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Store from '@material-ui/icons/Store';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getUserSummary } from '../../../actions/authActions/AuthActions';
import { 
  readAllNotifications,
  getUserNotifications,
  getOrderNotifications,
  getAdminSinglesOrderNotifications
} from '../../../actions/notificationActions/NotificationActions';
import {RootStore} from "../../../Store";

// Types.
import { UserNotificationType } from '../../admin/models/ModelTypes';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Styles.
import { useStyles } from './HeaderStyles';

// Import notification sound.
import notiAudioPath from "../../../audio/notificacion.mp3";

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

const Header = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');
  const decodedToken:any = localStorage.token !== undefined && localStorage.token !== '' ? jwtDecode(localStorage.token) : false;

  const isAdmin = (decodedToken) ? decodedToken.auth.includes('ROLE_ADMIN') : false;
  const isAdminSingles = (decodedToken) ? decodedToken.auth.includes('ROLE_ADMIN_SINGLES') : false;
  const isSeller = (decodedToken) ? decodedToken.auth.includes('ROLE_SELLER') : false;
  const isSuperSeller = (decodedToken) ? decodedToken.auth.includes('ROLE_SUPER_SELLER') : false;
  const isSellerAssociate = (decodedToken) ? decodedToken.auth.includes('ROLE_SELLER_ASSOCIATE') : false;

  const isAdminUser = () => {
    if (decodedToken) {
      if (isAdmin && !isAdminSingles) {
        return true;
      }
    }
    return false;
  };

  const isAdminOrAssociateUser = () => {
    if (decodedToken) {
      if ((isAdmin && !isAdminSingles) || isSellerAssociate) {
        return true;
      }
    }
    return false;
  };

  const isSellerUser = () => {
    if (decodedToken) {
      if ((isAdmin || isSeller || isSuperSeller) && !isSellerAssociate && !isAdminSingles) {
        return true;
      }
    }
    return false;
  };

  const isAdminSinglesUser = () => {
    if (decodedToken) {
      if (isAdminSingles) {
        return true;
      }
    }
    return false;
  };


  const requestOrderNotifications = () => {
    // Get order notifications based on the role.
    if (isAdminSinglesUser()) {
      dispatch(getAdminSinglesOrderNotifications());
    } else {
      dispatch(getOrderNotifications());
    }
  };

  // 3 minutes
  const MINUTE_MS = 300000;
  useEffect(() => {
    // Check if the user is already logged in.
    const authToken = localStorage.token;
    if (authToken !== undefined && authToken !== '') {
      dispatch(getUserSummary());
      dispatch(getUserNotifications());
      requestOrderNotifications();
    }

    // Re-call the user information every 3 minutes.
    const interval = setInterval(() => {
      dispatch(getUserSummary());
      dispatch(getUserNotifications());
      requestOrderNotifications();
    }, MINUTE_MS);
    return () => clearInterval(interval);
    
    // eslint-disable-next-line
  }, []);

  const [openDrawer, setOpenDrawer] = useState(false);
  const open = Boolean(openDrawer);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openMenuItem, setOpenMenuItem] = useState<boolean>(false);

  const [openMenuOrders, setOpenMenuOrders] = useState<boolean>(false);
  const [openMenuInventory, setOpenMenuInventory] = useState<boolean>(false);
  const [openMenuUsers, setOpenMenuUsers] = useState<boolean>(false);
  const [openMenuSettings, setOpenMenuSettings] = useState<boolean>(false);
  const [openMenuCategoriesTags, setOpenMenuCategoriesTags] = useState<boolean>(false);
  const [openMenuMarketplace, setOpenMenuMarketplace] = useState<boolean>(false);
  const [openMenuReports, setOpenMenuReports] = useState<boolean>(false);
  const [openMenuEvents, setOpenMenuEvents] = useState<boolean>(false);
  const [openMenuPrizes, setOpenMenuPrizes] = useState<boolean>(false);

  // Redux state.
  const reduxState = useSelector((state: RootStore) => state.auth);
  const userSummary = reduxState.userSummary;
  const orderReduxState = useSelector((state: RootStore) => state.order);
  const orderStatus = orderReduxState.actionStatus;
  const notificationsState = useSelector((state: RootStore) => state.userNotification);
  const { userNotifications, notificationsRead, orderNotifications } = notificationsState;

  useEffect(() => {
    requestOrderNotifications();
    
    // eslint-disable-next-line
  }, [orderStatus]);

  // Handle when to play the notification audio.
  useEffect(() => {
    if (orderNotifications!) {
      const receivedOrders = localStorage.receivedOrders;
      if (receivedOrders && receivedOrders!) {
        let condition = (Number(receivedOrders) < orderNotifications.ordersReceived);
        if (condition) playNotificationSound();
      } else {
        if (orderNotifications.ordersReceived > 0) playNotificationSound();
      }
      // Update localStorage variable with latest data.
      localStorage.setItem('receivedOrders', String(orderNotifications.ordersReceived));
    }

    // eslint-disable-next-line
  }, [orderNotifications]);

  // Plays the notifications audio.
  const playNotificationSound = () => {
    const audio = new Audio(notiAudioPath);
    audio.play();
  };

  const handleParentNavClick = (linkName: string) => {
    setOpenMenuItem(!openMenuItem);
    switch (linkName) {
      case 'orders':
        setOpenMenuOrders(!openMenuOrders);
        break;
      case 'inventory':
        setOpenMenuInventory(!openMenuInventory);
        break;
      case 'users':
        setOpenMenuUsers(!openMenuUsers);
        break;
      case 'settings':
        setOpenMenuSettings(!openMenuSettings);
        break;
      case 'categories/tags':
        setOpenMenuCategoriesTags(!openMenuCategoriesTags);
        break;
      case 'marketplace':
        setOpenMenuMarketplace(!openMenuMarketplace);
        break;
      case 'reports':
        setOpenMenuReports(!openMenuReports);
        break;
      case 'events':
        setOpenMenuEvents(!openMenuEvents);
        break;
      case 'prizes':
        setOpenMenuPrizes(!openMenuPrizes);
    }
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const openNotificationsBox = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setReadNotifications();
  };

  const closeNotificationsBox = () => {
    setAnchorEl(null);

    // Refresh the notifications.
    dispatch(getUserNotifications());
  };

  const setReadNotifications = () => {
    if (userNotifications !== undefined) {
      dispatch(readAllNotifications());
    }
  };

  const getNoticationNumber = () => {
    if (userNotifications !== undefined && !notificationsRead) {
      var count = 0;
      userNotifications.content.forEach((element:UserNotificationType) => {
        if (!element.read) {
          count++;
        }
      });

      return count;
    }

    return 0;
  };

  const openNotifications = Boolean(anchorEl);
  const id = openNotifications ? 'simple-popover' : undefined;

  const username = userSummary !== undefined ? userSummary.firstName : '';
  const userGreeting = t('header.greetings') + ', ' + username;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.appTitle}>
            { isAdminUser() ? t('header.title') : t('header.title-seller') }
          </Typography>
        <div className={classes.appIcons}>
          <Typography variant="inherit" className={classes.sectionMobile}>
            { userGreeting }
          </Typography>
          <IconButton aria-label={t('nav.orders')} color="inherit" href="/admin/orders?status=active">
            {(orderNotifications! && orderNotifications?.ordersActive !== 0) 
              ? <Badge badgeContent={`${orderNotifications?.ordersActive}`} color="secondary">
                  <StorefrontIcon />
                </Badge>
              : <StorefrontIcon />
            }
          </IconButton>
          <IconButton aria-label="Ordenes nuevas" color="inherit" href="/admin/orders?status=received">
            {(orderNotifications! && orderNotifications?.ordersReceived !== 0)
              ? <Badge badgeContent={`${orderNotifications?.ordersReceived}`} color="secondary">
                  <LocalMallIcon />
                </Badge>
              : <LocalMallIcon />
            }
          </IconButton>
          <IconButton aria-label="Muestra notificaciones" color="inherit" onClick={openNotificationsBox}>
            {getNoticationNumber() > 0 ?
              <Badge badgeContent={getNoticationNumber()} color="secondary">
                <NotificationsIcon />
              </Badge> :
              <NotificationsIcon />
            }
          </IconButton>
          <Popover
            id={id}
            open={openNotifications}
            anchorEl={anchorEl}
            color="primary"
            onClose={closeNotificationsBox}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            className={classes.notificationPopover}
          >
            <Paper className={classes.notifications}>
              <List component="nav" aria-label="Lista de notificaciones">
                {userNotifications !== undefined 
                  ? userNotifications.content.map((notification: UserNotificationType, index:number) =>  <Fragment key={index}>
                    <ListItem>
                      <div className={classes.nTitle}>
                        {notification.title}
                      </div>
                      <div className={classes.nMessage}>{notification.message}</div>
                      {!notification.read && 
                        <span></span>
                      }
                    </ListItem>
                    <Divider />
                  </Fragment>) 
                  : null }
                {userNotifications !== undefined && userNotifications.content.length === 0 &&
                  <div>{ t('notifications.no-notifications') }</div>
                }
              </List>
            </Paper>
          </Popover>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            href="/admin/user/details"
          >
            <AccountCircle />
          </IconButton>
        </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={openDrawer}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Fragment>
            <ListItem button onClick={() => handleParentNavClick('orders')} component={Button}>
              <ListItemIcon>
                <ShopTwoIcon />
              </ListItemIcon>
              <ListItemText primary={t('nav.orders')} />
              {openMenuOrders ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openMenuOrders} timeout="auto" unmountOnExit>
              { isSellerUser() ?
                <ListItem button className={classes.nested} key='add-order' href="/admin/add-order" component={Button}>
                  <ListItemText primary={t('nav.add-order')} />
                </ListItem> : null
              }
              { isSellerUser() || isAdminUser() ?
                <ListItem button className={classes.nested} key='add-order' href="/admin/add-point-of-sale-order" component={Button}>
                  <ListItemText primary={t('nav.add-pos-order')} />
                </ListItem> : null
              }
              <ListItem button className={classes.nested} key='orders' href="/admin/orders" component={Button}>
                <ListItemText primary={t('nav.manage-orders')} />
              </ListItem>
              { (isSellerUser() || isAdminSinglesUser() ) ? 
                <ListItem button className={classes.nested} key='orders-to-be-delivered' href="/admin/orders-to-be-delivered" component={Button}>
                  <ListItemText primary={t('orders.orders-to-be-delivered')} />
                </ListItem> : null 
              }
            </Collapse>
          </Fragment>
          {(!isAdminSinglesUser()) ?
            <Fragment>
              <ListItem button onClick={() => handleParentNavClick('inventory')} component={Button}>
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary={t('nav.inventory')} />
                {openMenuInventory ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openMenuInventory} timeout="auto" unmountOnExit>
                { isAdminUser()
                  ? <ListItem button className={classes.nested} key='add-item' href="/admin/add-item" component={Button}>
                      <ListItemText primary={t('nav.add-item')} />
                    </ListItem>
                  : null
                }
                <ListItem button className={classes.nested} key='inventory' href="/admin/items" component={Button}>
                  <ListItemText primary={t('nav.manage-items')} />
                </ListItem>
                {isAdminUser() ?
                  <Fragment>
                    <ListItem button className={classes.nested} key='add-bundle' href="/admin/add-bundle" component={Button}>
                      <ListItemText primary={t('nav.add-bundle')} />
                    </ListItem>
                    <ListItem button className={classes.nested} key='bundles' href="/admin/bundles" component={Button}>
                      <ListItemText primary={t('nav.manage-bundles')} />
                    </ListItem>
                  </Fragment> : null
                }
                {isAdminUser() ?
                  <Fragment>
                    <ListItem button className={classes.nested} key='bulk-items' href="/admin/bulk-item-update" component={Button}>
                      <ListItemText primary={t('nav.bulk-items')} />
                    </ListItem>
                  </Fragment> : null
                }
              </Collapse>
            </Fragment> : null
          }
          {isAdminUser() ?
            <Fragment>
              <ListItem button onClick={() => handleParentNavClick('users')} component={Button}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={t('nav.users')} />
                {openMenuUsers ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openMenuUsers} timeout="auto" unmountOnExit>
                  <Fragment>
                    <ListItem button className={classes.nested} key='add-user' href="/admin/add-user" component={Button}>
                      <ListItemText primary={t('nav.add-user')} />
                    </ListItem>
                    <ListItem button className={classes.nested} key='users' href="/admin/users" component={Button}>
                      <ListItemText primary={t('nav.manage-users')} />
                    </ListItem>
                    <ListItem button className={classes.nested} href="/admin/store-points" component={Button}>
                      <ListItemText primary={(getProjectConfig().STORE_CREDITS_NAME) ? getProjectConfig().STORE_CREDITS_NAME : t('nav.store-points')} />
                    </ListItem>
                  </Fragment>
              </Collapse>
            </Fragment> : null
          }
          {isAdminUser() ?
            <Fragment>
              <ListItem button onClick={() => handleParentNavClick('settings')} component={Button}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t('nav.settings')} />
                {openMenuSettings ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openMenuSettings} timeout="auto" unmountOnExit>
                  <Fragment>
                    <ListItem button className={classes.nested} href="/admin/slider-settings" component={Button}>
                      <ListItemText primary={t('nav.settings-slider')} />
                    </ListItem>
                    <ListItem button className={classes.nested} href="/admin/featured-boxes-settings" component={Button}>
                      <ListItemText primary={t('nav.settings-boxes')} />
                    </ListItem>
                  <ListItem button className={classes.nested} href="/admin/manage-navigation" component={Button}>
                    <ListItemText primary={t('nav.settings-nav')} />
                  </ListItem>
                  <ListItem button className={classes.nested} href="/admin/order-confirmation-settings" component={Button}>
                    <ListItemText primary={t('nav.confirmation-page')} />
                  </ListItem>
                  </Fragment>
              </Collapse>
            </Fragment> : null
          }
          {isAdminUser() ?
            <Fragment>
              <ListItem button onClick={() => handleParentNavClick('categories/tags')} component={Button}>
                <ListItemIcon>
                  <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary={t('nav.categories-tags')} />
                {openMenuCategoriesTags ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openMenuCategoriesTags} timeout="auto" unmountOnExit>
                <Fragment>
                  <ListItem button className={classes.nested} href="/admin/type-settings" component={Button}>
                    <ListItemText primary={t('nav.settings-types')} />
                  </ListItem>
                  <ListItem button className={classes.nested} href="/admin/tag-manager" component={Button}>
                    <ListItemText primary={t('nav.settings-tags')} />
                  </ListItem>
                </Fragment>
              </Collapse>
            </Fragment> : null
          }
          {(isAdminUser() || isAdminOrAssociateUser()) ?
            <Fragment>
              <ListItem button onClick={() => handleParentNavClick('marketplace')} component={Button}>
                <ListItemIcon>
                  <Store />
                </ListItemIcon>
                <ListItemText primary={t('nav.marketplace')} />
                {openMenuMarketplace ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openMenuMarketplace} timeout="auto" unmountOnExit>
                {isAdminUser() &&
                  <Fragment>
                    <ListItem button className={classes.nested} key='manage-singles' href="/admin/manage-singles" component={Button}>
                      <ListItemText primary={t('nav.add-singles')} />
                    </ListItem>
                    <ListItem button className={classes.nested} key='register-vendor' href="/admin/add-vendor" component={Button}>
                      <ListItemText primary={t('nav.add-vendor')} />
                    </ListItem>
                    <ListItem button className={classes.nested} key='get-all-vendor' href="/admin/vendors" component={Button}>
                      <ListItemText primary={t('nav.manage-vendors')} />
                    </ListItem>
                    <ListItem button className={classes.nested} href="/admin/app-variables-settings" component={Button}>
                      <ListItemText primary={t('nav.app-variables-settings')} />
                    </ListItem> 
                    <ListItem button className={classes.nested} href="/admin/create-singles-template" component={Button}>
                      <ListItemText primary={t('nav.create-singles-template')} />
                    </ListItem>
                  </Fragment>
                }
                {isAdminOrAssociateUser() &&
                  <ListItem button className={classes.nested} href="/admin/reports-associate" component={Button}>
                    <ListItemText primary={t('nav.reports-page-associate')} />
                  </ListItem>
                }
              </Collapse>
            </Fragment> : null
          }
          {isAdminUser() ?
            <Fragment>
              <ListItem button onClick={() => handleParentNavClick('reports')} component={Button}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={t('nav.reports-page')} />
                {openMenuReports ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openMenuReports} timeout="auto" unmountOnExit>
                <Fragment>
                  <ListItem button className={classes.nested} href="/admin/reports" component={Button}>
                    <ListItemText primary={t('nav.reports-page')} />
                  </ListItem>
                  <ListItem button className={classes.nested} href="/admin/reports-pending-orders" component={Button}>
                    <ListItemText primary={t('nav.reports-pending-orders')} />
                  </ListItem>
                  <ListItem button className={classes.nested} key='logs' href="/admin/logs" component={Button}>
                    <ListItemText primary='Logs' />
                  </ListItem>
                  <ListItem button className={classes.nested} href="/admin/looker-analytics" component={Button}>
                    <ListItemText primary={t('looker.page-title')} />
                  </ListItem>
                </Fragment>
              </Collapse>
            </Fragment> : null
          }
          { isAdminUser() || isSellerUser() ?
            <Fragment>
              <ListItem button onClick={() => handleParentNavClick('events')} component={Button}>
                <ListItemIcon>
                  <EmojiEventsIcon />
                </ListItemIcon>
                <ListItemText primary={t('nav.events-page')} />
                {openMenuEvents ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openMenuEvents} timeout="auto" unmountOnExit>
                <Fragment>
                  <ListItem button className={classes.nested} href="/admin/tournament" component={Button}>
                    <ListItemText primary={t('nav.events-page')} />
                  </ListItem>
                  <ListItem button className={classes.nested} href="/admin/tournament-prizes" component={Button}>
                    <ListItemText primary={t('prizes.prizes')} />
                  </ListItem>
                </Fragment>
              </Collapse>
            </Fragment> : null
          }
          <ListItem button key='settings' href="/" component={ Button }>
            <ListItemIcon><WebIcon /></ListItemIcon>
            <ListItemText primary={t('nav.user-pages')} />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

export default Header;
