// React.
import React, {Fragment} from 'react';

// Material UI.
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// Components.
import DeliveryList from '../deliveries/DeliveryList';

// Styles.
import useStyles from './DeliveriesPageStyles';

// Translations.
import { useTranslation } from 'react-i18next';


const DeliveriesPage = () => {
  const classes = useStyles();
  const [t] = useTranslation('global');

  return (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="md" className={classes.ordersWrapper}>
        <Paper elevation={3} square={false} variant="elevation" className={classes.paperWrapper}>
          <Typography gutterBottom variant="h5" component="h1" color="primary">
            { t('deliveries.my-deliveries') }
          </Typography>
          <DeliveryList/>
        </Paper>
      </Container>
    </Fragment>
  )
}

export default DeliveriesPage;