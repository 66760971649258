// React.
import { Fragment, useEffect, useState } from 'react';

// Material UI
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Components.
import Pagination from '../utils/ResultsPagination';
import OrderNotes from './OrderNotes';

// Moment.
import Moment from 'moment';

// Global config.
import { getProjectConfig } from '../../../getProjectConfig';

// Translations.
import { useTranslation } from 'react-i18next';

//  JWT decode token.
import jwtDecode from 'jwt-decode';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getOrdersToDeliver, refreshOrderView, updateOrderDeliver } from "../../../actions/orderActions/OrderActions";

// Styles.
import useStyles from './OrdersToBeDeliveredRowStyles';

const OrdersToBeDeliveredRow = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');

  // Initial values.
  const decodedToken: any = localStorage.token !== undefined && localStorage.token !== '' ? jwtDecode(localStorage.token) : false;
  const isAdminSingles = (decodedToken) ? decodedToken.auth.includes('ROLE_ADMIN_SINGLES') : false;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLoading, setCurrentLoading] = useState<string>('');
  const [openModalId, setOpenModalId] = useState<number>(-1);
  const url = window.location.href;
  const [pinId, setPinId] = useState<string>('');
  const [pinOrderId, setPinOrderId] = useState<number | null>(null);

  // Redux state.
  const orderState = useSelector((state: RootStore) => state.order);
  const { actionStatus, ordersToDeliver, refresh, orderFilters: filters } = orderState;

  useEffect(() => {
    if (actionStatus?.status === 'error') {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    let page = params.get('page');
    const pNumber = Number(page);

    if (page && page !== null) {
      const qParams = {
        ...filters,
        page: pNumber - 1,
        size: getProjectConfig().PAGES_SIZE,
        paged: true,
      };
      dispatch(getOrdersToDeliver(qParams));
      setCurrentPage(pNumber);
    } else {
      const qParams = {
        ...filters,
        page: 0,
        size: getProjectConfig().PAGES_SIZE,
        paged: true,
      };

      dispatch(getOrdersToDeliver(qParams));
      setCurrentPage(1);
    }

    dispatch(refreshOrderView(false));
    setCurrentLoading('');

    // eslint-disable-next-line
  }, [url, refresh, actionStatus, filters]);

  useEffect(() => {
    setCurrentLoading('');

    // eslint-disable-next-line
  }, [ordersToDeliver]);

  useEffect(() => {
    if (actionStatus?.status === 'error') {
      setCurrentLoading('');
    }

    // eslint-disable-next-line
  }, [actionStatus]);

  const updateOrderOnClick = (oId: number, colStatus: string, oNewStatus: string, order?: { user?: any }) => {
    if (colStatus.toLocaleLowerCase() === 'completado' && order?.user !== null && decodedToken.auth !== 'ROLE_ADMIN') {
      setPinOrderId(oId);
      return;
    }
    if (oId && oNewStatus) {
      setCurrentLoading(`${colStatus}-${oId}`);
      dispatch(updateOrderDeliver({ orderId: oId, status: oNewStatus}));
    }
  };
  
  const handleCloseModal = () => {
    setOpenModalId(-1);
  };

  const handleOpenModal = (orderId: number) => {
    setOpenModalId(orderId);
  }

  const isDiabledCard = (cStatus:string, oStatus: string) => {
    const orderStatus = oStatus.toLocaleLowerCase();
    if (cStatus === 'procesando' && orderStatus === 'orden recibida') {
      return false;
    }
    if (cStatus === 'listo para recoger' && orderStatus === 'procesando') {
      return false;
    }
    if (cStatus === 'completado' && orderStatus === 'listo para recoger') {
      return false;
    }

    return true;
  };

  const isCurrentStatus = (cStatus: string, oStatus: string) => {
    const orderStatus = oStatus.toLocaleLowerCase();
    if (cStatus === orderStatus) {
      return true;
    }
    return false;
  };


  const formatUser = (email: string) => {
    const splited = email.split('@');
    if (splited[0]) return splited[0];
    return null;
  }

  const handleClosePinModal = () => {
    setPinOrderId(null); 
    setPinId('');
  }
  
  const handleChangePin = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 4) {
      setPinId(e.target.value);
    }
  }

  const handleSaveCompleteOrder = (oId: number, colStatus: string, oNewStatus: string, pinId?: string) => {
    if (pinId !== undefined && pinId !== '') {
      if (oId && oNewStatus) {
        setCurrentLoading(`${colStatus}-${oId}`);
        dispatch(updateOrderDeliver({ orderId: oId, status: oNewStatus, pinId: pinId}));
      }
    }
    handleClosePinModal();
  };

  const handleButtonClick = (oId: number, colStatus: string, oNewStatus: string, pinId?: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleSaveCompleteOrder(oId, colStatus, oNewStatus, pinId);
  };

  return (
    <div className={classes.root}>    
      <Divider />
      {ordersToDeliver?.ordersList.map(order => 
        <Fragment key={order.orderId}>
          <Grid container spacing={2} className={classes.orderRow}>
            <Grid item xs={12} sm={12} md={4}>
              <Typography className={classes.boldText}>{order.code}</Typography>
              <Typography>{t('orders.client-name')}: {order.customerName}</Typography>
              <Typography>{t('orders.created')}: {Moment(order.creationDate).format('MM/DD/YYYY HH:mm')}</Typography>
              <div className={classes.pickUpIdContainer}>
                <Typography>{t('deliveries.pickupId')}</Typography>
                <Typography>
                      {order.pickUpId !== "" && order.pickUpId !== null ? order.pickUpId : t("deliveries.no-pickupId")}
                </Typography>
              </div>
              <Link className={classes.boldText} component="button" onClick={() => handleOpenModal(order.orderId)}>
                { order.notes.length > 0 ?
                  <div className={classes.hasOrdersContainer}>
                    <p>
                      <span className={classes.orderCountCopy}>{t('orders.view-notes')} </span>
                      ({order.notes.length} {t('orders.notes')})
                    </p>
                    <PriorityHigh/>
                  </div>
                  : <p className={classes.orderCountCopy}>{t('orders.view-notes')} ({order.notes.length} {t('orders.notes')})</p>
                }
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Card className={
                `${isDiabledCard('orden recibida', order.status) ? classes.disabledCard : ''}
                 ${isCurrentStatus('orden recibida', order.status) ? classes.currentStatus : ''}`
                }>
                <CardActionArea disabled={isDiabledCard('orden recibida', order.status) || (currentLoading === `orden recibida-${order.orderId}`)}>
                  <CardContent>
                    <Typography className={classes.ctaTitle}>
                      Recibido
                    </Typography>
                    {(currentLoading === `orden recibida-${order.orderId}`) && 
                      <Typography variant="caption">{t('orders.processing')}</Typography>
                    }
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Card className={
                `${isDiabledCard('procesando', order.status) ? classes.disabledCard : ''} 
                ${isCurrentStatus('procesando', order.status) ? classes.currentStatus : ''}`
              }>
                <CardActionArea 
                  disabled={isDiabledCard('procesando', order.status) || (currentLoading === `procesando-${order.orderId}`)}
                  onClick={() => updateOrderOnClick(order.orderId, 'procesando', 'Procesando')}
                >
                  <CardContent>
                    <Typography className={classes.ctaTitle}>
                      {!isDiabledCard('procesando', order.status) && <span>{t('orders.move-to')}<br /></span>}
                      Procesando
                    </Typography>
                    {(order.processedBy) &&
                      <>
                      <Typography variant="caption" className={classes.breakWord}>{formatUser(order.processedBy)}</Typography><br />
                      <Typography variant="caption">{Moment(order.processedByDate).format('MM/DD/YYYY HH:mm')}</Typography>
                      </>
                    }
                    {(currentLoading === `procesando-${order.orderId}` && actionStatus?.status !== 'error') &&
                      <Typography variant="caption">{t('orders.processing')}</Typography>
                    }
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Card className={
                `${(isDiabledCard('listo para recoger', order.status) || isAdminSingles) ? classes.disabledCard : ''} 
                ${isCurrentStatus('listo para recoger', order.status) ? classes.currentStatus : ''}`
              }>
                <CardActionArea 
                  disabled={isDiabledCard('listo para recoger', order.status) || (currentLoading === `listo para recoger-${order.orderId}`) || isAdminSingles}
                  onClick={() => updateOrderOnClick(order.orderId, 'listo para recoger', 'Listo para Recoger')}
                >
                  <CardContent>
                    <Typography className={classes.ctaTitle}>
                      {!isDiabledCard('listo para recoger', order.status) && <span>{t('orders.move-to')}<br/></span>}
                      Recibido por Tienda
                    </Typography>
                    {(order.readyPickupBy) &&
                      <>
                      <Typography variant="caption" className={classes.breakWord}>{formatUser(order.readyPickupBy)}</Typography><br />
                      <Typography variant="caption">{Moment(order.readyPickupByDate).format('MM/DD/YYYY HH:mm')}</Typography>
                      </>
                    }
                    {(currentLoading === `listo para recoger-${order.orderId}`) &&
                      <Typography variant="caption">{t('orders.processing')}</Typography>
                    }
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Card className={
                `${(isDiabledCard('completado', order.status) || isAdminSingles) ? classes.disabledCard : ''} 
                ${isCurrentStatus('completado', order.status) ? classes.currentStatus : ''}`
              }>
                <CardActionArea 
                  disabled={isDiabledCard('completado', order.status) || (currentLoading === `completado-${order.orderId}`) || isAdminSingles}
                  onClick={() => updateOrderOnClick(order.orderId, 'completado', 'Completado')}
                >
                  <CardContent>
                    <Typography className={classes.ctaTitle}>
                      {!isDiabledCard('completado', order.status) && <span>{t('orders.move-to')}<br /></span>}
                      Completado
                    </Typography>
                    {(order.completedBy) &&
                      <>
                      <Typography variant="caption" className={classes.breakWord}>{formatUser(order.completedBy)}</Typography><br />
                      <Typography variant="caption">{Moment(order.completedDate).format('MM/DD/YYYY HH:mm')}</Typography>
                      </>
                    }
                    {(currentLoading === `completado-${order.orderId}`) &&
                      <Typography variant="caption">{t('orders.processing')}</Typography>
                    }
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          <Modal
            open={openModalId === order.orderId}
            onClose={handleCloseModal}
            aria-labelledby="Orer notes list."
            id={`order-notes-${order.orderId}`}
          >
            <Paper elevation={3} className={classes.modalPaper}>
              <IconButton className={classes.closeModal} aria-label="close modal" component="span" onClick={() => handleCloseModal()}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="primary">{t('orders.order')} {order.code}</Typography>
              <OrderNotes orderId={order.orderId} orderNotes={order.notes}/>
            </Paper>
          </Modal>
          {decodedToken.auth !== 'ROLE_ADMIN' && pinOrderId === order.orderId && (
            <Modal
              open={pinOrderId === order.orderId}
              onClose={handleClosePinModal}
              aria-labelledby="Confirm Modal"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Paper
                elevation={3}
                style={{
                  width: '400px',
                  padding: '24px',
                  display: 'flex',
                  flexDirection: 'column',
                  outline: 'none',
                }}
              >
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={handleClosePinModal}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {t('orders.pin-code-modal-message')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="pin-modal-input"
                      name="pinId"
                      value={pinId}
                      onChange={handleChangePin}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleButtonClick(pinOrderId as number, 'completado', 'Completado', pinId)}
                      disabled={pinId.length !== 4}
                    >
                      {t('orders.complete-order')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Modal>
          )}
          <Divider />
        </Fragment>
      )}
      {
        (ordersToDeliver?.totalPages !== undefined && ordersToDeliver?.totalPages > 1)
          ? (<Pagination current={currentPage} path="/admin/orders-to-be-delivered" pagesNumber={ordersToDeliver!.totalPages} />) : ''
      }
    </div>
  )
}

export default OrdersToBeDeliveredRow;
